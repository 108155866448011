// src/store/userSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
  theme: 'light' | 'dark';
  // Add other user configurations here in future if needed
}

const initialState: UserState = {
  theme: 'light',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<'light' | 'dark'>) => {
      state.theme = action.payload;
    },
  },
});

export const { setTheme } = userSlice.actions;
export default userSlice.reducer;