import React, { useState } from 'react';
import {
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  SelectChangeEvent,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { StripeCheckoutForm } from '../StripeCheckout/StripeCheckoutForm';

const pricingPlans = [
  {
    title: 'Freemium',
    price: '$0',
    price_id: 'price_1QSEd9Ab41VA2yECZCgS0IVO',
    features: [
      'Limited tests',
      'Limited AI assistance',
      'Basic progress tracking',
    ],
  },
  {
    title: 'Single Test',
    price: '$5',
    price_id: 'price_1QSFNdAb41VA2yECRNDt1EpB',
    features: [
      'Access to one mock test of your choice',
      'Single explanation for each question',
      'Available tests: TOEFL, IELTS, SAT, ACT, CCAT, GRE',
    ],
  },
  {
    title: 'Basic',
    price: '$19/month',
    price_id: 'price_1QSFPJAb41VA2yECjmlj2aUy',
    features: [
      '10 tests',
      'More AI assistance',
      'Talk to the assistant and get detailed answers',
      'Enhanced progress tracking',
    ],
  },
  {
    title: 'Premium',
    price: '$29/month',
    price_id: 'price_1QSFQwAb41VA2yECHop1cpK1',
    features: [
      '20 tests',
      'Full AI assistance'
    ],
  },
  {
    title: 'Ultimate',
    price: '$49/month',
    price_id: 'price_1QSFRwAb41VA2yEChxT5dI8t',
    features: [
      'Unlimited tests',
      'Full AI assistance',
      'Advanced performance analysis',
      'Personalized study plans',
    ],
  },
];

const PricingPlans: React.FC = () => {
  const navigate = useNavigate();
  const [selectedTest, setSelectedTest] = useState('');
  const [showStripeCheckout, setShowStripeCheckout] = useState(false); // State to control Stripe checkout visibility
  const [selectedPlan, setSelectedPlan] = useState(''); // State to store the selected plan
  const theme = useTheme();

  const handleGetStarted = (planTitle: string) => {
    if (planTitle === 'Single Test' && !selectedTest) {
      // ... handle case where no test is selected ...
    } else if (planTitle === 'Free') {
      // ... redirect to free tests ...
    } else {
      // For other plans, redirect to checkout with plan details
      const plan = pricingPlans.find((p) => p.title === planTitle);
      if (plan) {
        const items = [{
          name: plan.title + ' Plan',
          price: parseFloat(plan.price.replace(/[^0-9.]/g, '')),
          quantity: 1,
          price_id: plan.price_id,
          selectedTest: selectedTest,
          features: plan.features, // Add features to the items object
        }];
        navigate(`/checkout`, { state: { items } }); // Pass items in state
      }
    }
  };


  const handleTestChange = (event: SelectChangeEvent) => {
    setSelectedTest(event.target.value);
  };

  return (
    <Container maxWidth="md" sx={{ my: 5 }}> {/* Reduced container width */}
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          mb: 5,
          fontWeight: 'bold',
          color: theme.palette.primary.main,
        }}
      >
        Pricing Plans
      </Typography>

      <Box
        sx={{
          minHeight: 300, // Adjust the value as needed
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(2, 1fr)',
          },
          gap: 3,
          justifyContent: 'center',
          width: '100%',
          my: 5,
        }}
      >
        {pricingPlans.map((plan) => (
          <Card
            key={plan.title}
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              border: 'none', // Remove border
              borderRadius: 3, // Increase border-radius
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // More pronounced shadow
              transition: 'transform 0.2s', // Add a hover transition
              '&:hover': {
                transform: 'translateY(-4px)', // Lift on hover
              },
            }}
          >
            <CardContent sx={{ flexGrow: 1, p: 4 }}>
              <Typography
                variant="h5"
                component="div"
                align="center"
                sx={{
                  fontWeight: 'bold',
                  mb: 2,
                  color: theme.palette.primary.main, // Use primary color from theme
                }}
              >
                {plan.title}
              </Typography>
              <Typography
                variant="h6"
                align="center"
                sx={{ mb: 3, color: theme.palette.secondary.main }} // Use secondary color
              >
                {plan.price}
              </Typography>
              <ul style={{ listStyle: 'none', padding: 0 }}>
                {plan.features.map((feature) => (
                  <li key={feature} style={{ marginBottom: '10px' }}>
                    <Typography
                      variant="body2"
                      sx={{ color: theme.palette.text.primary }} // Use theme text color
                    >
                      <span style={{ marginRight: '8px', display: 'inline-block' }}>
                        &#8226;
                      </span>
                      {feature}
                    </Typography>
                  </li>
                ))}
              </ul>

              {plan.title === 'Single Test' && ( // Conditionally render test selection
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="test-select-label">Select Test</InputLabel>
                  <Select
                    labelId="test-select-label"
                    id="test-select"
                    value={selectedTest}
                    label="Select Test"
                    onChange={handleTestChange}
                  >
                    <MenuItem value="toefl">TOEFL</MenuItem>
                    <MenuItem value="ielts">IELTS</MenuItem>
                    <MenuItem value="sat">SAT</MenuItem>
                    <MenuItem value="act">ACT</MenuItem>
                    <MenuItem value="ccat">CCAT</MenuItem>
                    <MenuItem value="gre">GRE</MenuItem>
                  </Select>
                </FormControl>
              )}
            </CardContent>
            <CardActions sx={{ justifyContent: 'center', p: 3 }}>
              {showStripeCheckout && selectedPlan === plan.title ? (
                <StripeCheckoutForm />
              ) : (
                <Button
                  variant="contained"
                  size="medium"
                  onClick={() => handleGetStarted(plan.title)}
                  sx={{
                    backgroundColor: theme.palette.secondary.main,
                    color: 'white',
                    borderRadius: 20,
                    px: 4,
                    py: 1,
                    transition: 'transform 0.2s',
                    '&:hover': {
                      transform: 'scale(1.05)',
                    },
                  }}
                >
                  Get Started
                </Button>
              )}
            </CardActions>
          </Card>
        ))}
      </Box>
    </Container>
  );
};

export default PricingPlans;