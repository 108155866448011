// src/App.tsx
import React, { useEffect, useState } from 'react';
import { Routes, Route, Link, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
} from '@mui/material';
import AuthScreen from './components/Auth/AuthScreen';
import { useDispatch, useSelector } from 'react-redux';
import { clearSession, setSession } from './store/authSlice';
import { createClient } from '@supabase/supabase-js';
import { RootState } from './store';
import AuthRedirect from './components/Auth/AuthRedirect';
import TestListing from './components/TestsListing/TestsListing';
import PricingPlans from './components/PricingPlans/PricingPlans';
import { ThemeProvider } from '@mui/material/styles';
import { lightTheme, darkTheme } from './styles/theme';
import { setTheme } from './store/userSlice';
import { StripeCheckoutForm, StripeCheckoutReturn } from './components/StripeCheckout/StripeCheckoutForm';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL as string,
  process.env.REACT_APP_SUPABASE_ANON_KEY as string
);

const App: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const theme = useSelector((state: RootState) => state.user.theme);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    const { data } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN' || event === 'INITIAL_SESSION') {
        dispatch(setSession(session));
      } else if (event === 'SIGNED_OUT') {
        dispatch(clearSession());
      }
    });

    return () => {
      data.subscription.unsubscribe();
    };
  }, [dispatch]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await supabase.auth.signOut();
    handleMenuClose();
    navigate('/auth');
  };

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    dispatch(setTheme(newTheme));
  };

  return (
    <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            MockTests
          </Typography>
          {user ? (
            <>
              <IconButton onClick={handleMenuOpen} color="inherit">
                <Avatar
                  alt={user.email || ''}
                  src={user.user_metadata?.avatar_url || ''}
                />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
                <MenuItem onClick={handleLogout}>Log Out</MenuItem>
              </Menu>
            </>
          ) : (
            <Button color="inherit" component={Link} to="/auth">
              Login
            </Button>
          )}
          <Button color="inherit" onClick={toggleTheme}>
            {theme === 'dark' ? 'Light Mode' : 'Dark Mode'}
          </Button>
        </Toolbar>
      </AppBar>

      <Routes>
        <Route path="/" element={<TestListing />} />
        <Route path="/auth" element={<AuthScreen />} />
        <Route path="/auth/callback" element={<AuthRedirect />} />
        <Route path="/pricing" element={<PricingPlans />} />
        <Route path="/checkout" element={<StripeCheckoutForm />} /> {/* Add the checkout route */}
        <Route path="/return" element={<StripeCheckoutReturn />} /> {/* Add the checkout return route */}
        {/* Add other routes for your application here */}
      </Routes>
    </ThemeProvider>
  );
};

export default App;