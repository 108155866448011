import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import { useDispatch } from 'react-redux';
import { setSession } from '../../store/authSlice';
import httpService from '../../services/httpService';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL as string,
  process.env.REACT_APP_SUPABASE_ANON_KEY as string
);

const AuthRedirect: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const url = window.location.href;
    const [, hash] = url.split('#'); // Split the URL at the hash (#)

    if (hash && navigate && dispatch) {
      const searchParams = new URLSearchParams(hash); 

      const access_token = searchParams.get('access_token');
      const refresh_token = searchParams.get('refresh_token');

      if (access_token && refresh_token) {
        (async () => { // Use an async IIFE
          try {
            // Set the auth state in Supabase
            const sessionResponse = await supabase.auth.setSession({
              access_token,
              refresh_token,
            });
      
            await httpService.post('users', { userId: sessionResponse.data.user?.id, email: sessionResponse.data.user?.email });

            console.log('Session set successfully!', sessionResponse); // Log the response

            console.log('Session set successfully!');

            // Get the updated session and save it in Redux
            const { data } = await supabase.auth.getSession();
            dispatch(setSession(data.session));

            // Redirect the user to the desired page
            navigate('/dashboard');
          } catch (error) {
            console.error(
              'Error setting session or getting session:',
              error
            );
          }
        })(); // Immediately invoke the IIFE
      }
    }
  }, [navigate, dispatch]);

  return null;
};

export default AuthRedirect;