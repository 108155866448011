// src/components/Auth/AuthScreen.tsx
import './Auth.css';
import { useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { useNavigate } from 'react-router-dom';
// import HCaptcha from '@hcaptcha/react-hcaptcha';
// import { Button } from '@mui/material';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL as string,
  process.env.REACT_APP_SUPABASE_ANON_KEY as string
);

const AuthScreen: React.FC = () => {
  // to access redux auth state
  // const { user, accessToken } = useSelector((state: RootState) => state.auth);

  const navigate = useNavigate();
  const [showSignIn, setShowSignIn] = useState(true);
  // const [captchaToken, setCaptchaToken] = useState("")

  // const setCaptchaTokenNow = (token: string) => {
  //   console.log(token)
  //   setCaptchaToken(token)
  // }

  supabase.auth.onAuthStateChange((event, session) => {
    if (event === 'SIGNED_IN') {
      navigate('/dashboard');
    }
  });


  return (
    <div className="auth-container">
      <div className="auth-card">
        <div className="auth-header">
          <h2 className="auth-title">
            {showSignIn ? 'Welcome Back!' : "Let's Get Started"}
          </h2>
          <p className="auth-subtitle">
            {showSignIn
              ? 'Sign in to your account'
              : 'Create a new account'}
          </p>
        </div>
        <div className="auth-content">
          <Auth
            supabaseClient={supabase}
            appearance={{ theme: ThemeSupa }}
            providers={["google"]}
            // queryParams={{
            //   captchaToken
            // }}
            socialLayout="horizontal"
            view={showSignIn ? "sign_in" : "sign_up"}
            redirectTo={window.location.origin + "/auth/callback"}
          />
          {/* {!showSignIn && (
            <HCaptcha
              sitekey={process.env.REACT_APP_H_CAPTCHA_SITE_KEY as string}
              onVerify={(token) => {
                setCaptchaTokenNow(token)
              }}
            />
          )} */}
        </div>
      </div>
    </div>
  );
};

export default AuthScreen;