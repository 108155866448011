import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux'; 
import store from './store'; // Assuming your store is in './store'
import { BrowserRouter } from 'react-router-dom'; // Import BrowserRouter here

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}> {/* Provide the Redux store */}
      <BrowserRouter> {/* Move BrowserRouter to index.tsx */}
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
