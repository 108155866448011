import axios from 'axios';
import store, { RootState } from '../store';
import { Constants } from '../constants';

// Create an instance of axios with base URL and default settings
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URI, // Replace with your actual base URL
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 10000, // Set a timeout for requests (optional)
});

// Add a request interceptor for adding authorization token
apiClient.interceptors.request.use(
  (config) => {
    // First, try to get the token from the Redux store
    let token = (store.getState() as RootState).auth.accessToken;

    // If no token is in the Redux store, check localStorage
    if (!token) {
      const localStorageToken = localStorage.getItem(Constants.Auth.AUTH_TOKEN_KEY);
      if (localStorageToken) {
        const parsedToken = JSON.parse(localStorageToken);
        if (parsedToken?.access_token) {
          token = parsedToken.access_token;
        }
      }
    }

    // If a token is found, add it to the request header
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// HTTP Service with generic support for GET, POST, PUT, DELETE
export const httpService = {
  // GET request with a generic response type
  get: async <T>(url: string, params?: Record<string, unknown>): Promise<T> => {
    const response = await apiClient.get<T>(url, { params });
    return response.data; // Returns data of type T
  },

  // POST request with a generic response type
  post: async <T>(url: string, data?: Record<string, unknown>): Promise<T> => {
    const response = await apiClient.post<T>(url, data);
    return response.data; // Returns data of type T
  },

  // PUT request with a generic response type
  put: async <T>(url: string, data?: Record<string, unknown>): Promise<T> => {
    const response = await apiClient.put<T>(url, data);
    return response.data; // Returns data of type T
  },

  // DELETE request with a generic response type
  delete: async <T>(url: string, params?: Record<string, unknown>): Promise<T> => {
    const response = await apiClient.delete<T>(url, { params });
    return response.data; // Returns data of type T
  },
};

export default httpService;
