import React from 'react';
import {
  Typography,
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const tests = [
  {
    id: 'toefl',
    name: 'TOEFL',
    description: 'Test of English as a Foreign Language',
  },
  {
    id: 'ielts',
    name: 'IELTS',
    description: 'International English Language Testing System',
  },
  {
    id: 'sat',
    name: 'SAT',
    description: 'Scholastic Assessment Test',
  },
  {
    id: 'act',
    name: 'ACT',
    description: 'American College Testing',
  },
  {
    id: 'gre',
    name: 'GRE',
    description: 'Graduate Record Examination',
  },
  {
    id: 'mcat',
    name: 'MCAT',
    description: 'Medical College Admission Test',
  },
];

const TestListing: React.FC = () => {
  const navigate = useNavigate();

  const handleTakeTest = (testId: string) => {
    navigate(`/test/${testId}`);
  };

  return (
    <div>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ textAlign: 'center', marginTop: '20px' }}
      >
        Available Mock Tests
      </Typography>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
          gap: 3,
          width: '80%',
          margin: '20px auto',
        }}
      >
        {tests.map((test) => (
          <Card
            key={test.id}
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography variant="h6" component="div">
                {test.name}
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {test.description}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                variant="contained"
                onClick={() => handleTakeTest(test.id)}
                fullWidth
              >
                Take Test
              </Button>
            </CardActions>
          </Card>
        ))}
      </Box>
    </div>
  );
};

export default TestListing;